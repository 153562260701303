import React, { useState } from 'react';
import ReactGA from "react-ga4";

import { welcomeMessage } from './components/Ascii';
import MenuBar from './components/MenuBar';
import MainView from './components/MainView';

ReactGA.initialize("G-TBNMGF30G5");

console.log(welcomeMessage);
setTimeout(()=>console.log(`.....`), 1000);
setTimeout(()=>console.log(`....`), 1750);
setTimeout(()=>console.log(`...`), 2500);
setTimeout(()=>console.log(`..`), 3250);
setTimeout(()=>console.log(`.`), 4000);
setTimeout(()=>console.log('stick around a while'), 6500);
setTimeout(()=>console.log("(or don't, life is short)"), 9000);

function App() {
  const [activeComponent, setActiveComponent] = useState('About');

  const handleComponentChange = (component) => {
    setActiveComponent(component);
    ReactGA.send({ hitType: "pageview", page: component, title: "Clicked" });
  };

  return (
    <div className="App">
        <MenuBar onComponentChange={handleComponentChange} activeComponent={activeComponent} />
        <MainView activeComponent={activeComponent} />
    </div>
  );
}

export default App;
