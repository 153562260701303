import React, { useState, useEffect } from 'react';

import { graphicImages as gifs, placeholder } from './Images';


/** vars **/
let isCarouselActivated = false;
let usedGraphics = [];
let graphicImages = gifs;
const totalNumImages = graphicImages.length-1;

/** helpers **/
const randomNonZeroNumber = (maxValue) => {
  const randomNumber = Math.floor(Math.random() * (maxValue - 1)) + 1;
  return randomNumber;
}

/** components **/
const ImageCarousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {    
    // set display duration depending the prior image type
    let interval = currentImageIndex === 0 
      ? 1750 // "no-signal" gif duration 
      : 3000; // all other gif durations
 

    // alternate between 0th index image and all other images in array 
    const timer = setInterval(() => {
      currentImageIndex === 0 
        ? setCurrentImageIndex(randomNonZeroNumber(graphicImages.length-1))
        : setCurrentImageIndex(0);
    }, interval);

    // ensures no repeats
    if(currentImageIndex !== 0) {
      usedGraphics.push(graphicImages.splice(currentImageIndex, 1))

      if(usedGraphics.length >= totalNumImages){
        usedGraphics.unshift(graphicImages[0])
        graphicImages = usedGraphics;
        usedGraphics = [];
      }
    }

    // carousel will always start with "no-signal"
    if (!isCarouselActivated) {
      setCurrentImageIndex(0)
      isCarouselActivated = true;
    }

    // clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [currentImageIndex]);

  return (
    <div className="square-image-container">
      <img
        className="square-image"
        src={graphicImages[currentImageIndex]}
        alt='alternating gif'
      /> 
    </div>
  );
};

// broadcast intro image
const Placeholder = () => {
  return (
    <div className="square-image-container">
      <img
        className="square-image"
        src={placeholder}
        alt='test card placeholder'
      /> 
    </div>
  );
}

export {
  ImageCarousel,
  Placeholder
}