import noSignal from './assets/static.gif'
import placeholder from './assets/placeholder.gif'
import placeholderStatic from './assets/placeholder-static.jpg'

import spectrograms from './assets/research/spectrograms.jpg'
import widecnn from './assets/research/widecnn.png'
import deepcnn from './assets/research/deepcnn.png'
import simplecnn from './assets/research/simplecnn.png'
import deltacnn from './assets/research/delta-cnn.png'
import deltafreq from './assets/research/delta-freq.png'
import deltachannel from './assets/research/delta-channels.png'

import g1 from './assets/gifs/g1.gif'
import g2 from './assets/gifs/g2.gif'
import g3 from './assets/gifs/g3.gif'
import g4 from './assets/gifs/g4.gif'
import g5 from './assets/gifs/g5.gif'
import g6 from './assets/gifs/g6.gif'
import g7 from './assets/gifs/g7.gif'
import g8 from './assets/gifs/g8.gif'
import g9 from './assets/gifs/g9.gif'
import g10 from './assets/gifs/g10.gif'
import g11 from './assets/gifs/g11.gif'
import g12 from './assets/gifs/g12.gif'
import g13 from './assets/gifs/g13.gif'
import g14 from './assets/gifs/g14.gif'
import g15 from './assets/gifs/g15.gif'
import g16 from './assets/gifs/g16.gif'
import g17 from './assets/gifs/g17.gif'
import g18 from './assets/gifs/g18.gif'
import g19 from './assets/gifs/g19.gif'
import g20 from './assets/gifs/g20.gif'
import g21 from './assets/gifs/g21.gif'
import g22 from './assets/gifs/g22.gif'
import g23 from './assets/gifs/g23.gif'
import g24 from './assets/gifs/g24.gif'
import g25 from './assets/gifs/g25.gif'
import g26 from './assets/gifs/g26.gif'
import g27 from './assets/gifs/g27.gif'
import g28 from './assets/gifs/g27.gif'
import g29 from './assets/gifs/g28.gif'
import g30 from './assets/gifs/g30.gif'
import g31 from './assets/gifs/g31.gif'
import g32 from './assets/gifs/g32.gif'
import g33 from './assets/gifs/g33.gif'
import g34 from './assets/gifs/g34.gif'
import g35 from './assets/gifs/g35.gif'
import g36 from './assets/gifs/g36.gif'
import g37 from './assets/gifs/g37.gif'
import g38 from './assets/gifs/g37.gif'
import g39 from './assets/gifs/g38.gif'
import g40 from './assets/gifs/g40.gif'


const graphicImages = [
    noSignal, g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, 
    g11, g12, g13, g14, g15, g16, g17, g18, g19, g20,
    g21, g22, g23, g24, g25, g26, g27, g28, g29, g30,
    g31, g32, g33, g34, g35, g36, g37, g38, g39, g40, ];

const cnns = [ simplecnn, widecnn, deepcnn ];
const deltas = [ deltacnn, deltachannel, deltafreq ];

export {
    graphicImages,
    placeholder,
    placeholderStatic,
    noSignal,
    spectrograms,
    cnns, simplecnn, widecnn, deepcnn,
    deltas, deltacnn, deltachannel, deltafreq
}