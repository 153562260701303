
import React from 'react';
import { About, Research, Resume } from "./Sections";
import { ContactForm, GoogleForm } from './ContactForm';

const components = {
    About: <About />,
    // Contact: <ContactForm />,
    Contact: <GoogleForm />,
    Resume: <Resume />,
    Research: <Research />,
}

const MainView = ({activeComponent}) => {
    return (
        <div className="main-view">
            {components[activeComponent]}
        </div>
    );
}

export default MainView;