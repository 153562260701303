import { spectrograms } from "./Images";
import { ResearchSlides } from "./ResearchImageCarousel";

const About = () => {
    return (
        <div className="about-section">
            <h2>Hi my name's Kaivon 👋</h2>
            
            <p>👾 - I'm a solution focused engineer working at the intersection of human-computer interaction and data-driven development with a proven record of success and adaptability to meet project needs.</p>
            <h4>_____</h4>
            
            <p>🛠️ - With over six years of industry and research experience I've developed skills specializing in end-to-end development, emergent technologies, audio signal processing, and machine learning.</p>
            <h4>_____</h4>
            
            <p>📈 - In the last five years I grew voice and emerging platforms to be one of National Public Radio's most dominant digital listening experiences while also supporting NPR's content distribution infrastructure. During this time I authored greenfield projects to bring radio listening into the digital era, developed microservices to modernize NPR's infrastructure, and increased NPR's sponsorship revenue by the order of millions.</p>
            <h4>_____</h4>
            
            <p>🎙️ - My public facing projects include <a href="https://www.npr.org/about-npr/560999519/listening-to-npr-with-alexa" target="_blank" rel="noreferrer">NPR for Alexa</a>, <a href="https://www.npr.org/about-npr/563853221/listening-to-npr-on-google-home" target="_blank" rel="noreferrer">NPR for Google Home</a>, the <a href="https://one.npr.org/" target="_blank" rel="noreferrer">NPR One WebApp</a>, and <a href="https://www.npr.org/sections/npr-extra/2017/01/03/508026541/npr-one-samsung-gear" target="_blank" rel="noreferrer">NPR One for Samsung Gear</a> (currently inactive). My work has reached hundreds of thousands of users and has been featured in: 
            <li><a href="https://youtu.be/DsQUS8iVlQ8?t=3883" target="_blank" rel="noreferrer">Alexa Live Keynote (2022)</a></li>
            <li><a href="https://developer.amazon.com/en-US/blogs/alexa/alexa-skills-kit/2023/02/npr-alexa-routines-kit-february-2023" target="_blank" rel="noreferrer">Amazon's Developer Blog</a></li>
            <li><a href="https://www.engadget.com/2019-04-08-amazon-alexa-long-form-news.html" target="_blank" rel="noreferrer">Engadget</a></li>
            <li><a href="https://www.niemanlab.org/2019/04/asking-alexa-for-news-no-longer-has-to-stop-with-the-latest-headlines/" target="_blank" rel="noreferrer">Neiman Lab</a></li>
            <li><a href="https://niemanreports.org/articles/reimagining-audio-news/" target="_blank" rel="noreferrer">Neiman Reports</a></li>
            {/* <li><a href="https://npr.codes/theres-a-browser-in-your-smart-speaker-66b95c630000" target="_blank" rel="noreferrer">NPR Codes</a> (self promotion)</li> */}
            <li><a href="https://voicebot.ai/2022/07/25/npr-debuts-alexa-routines/" target="_blank" rel="noreferrer">VoiceBot AI</a></li>
            </p>
            <h4>_____</h4>
          
            <p>👨🏽‍🔬 - Prior to NPR I researched under the direction of UPF’s cross-disciplinary <a href="https://www.upf.edu/web/mtg" target="_blank" rel="noreferrer">Music Technology Group</a> (Essentia, Vocaloid, Freesound, Reactable, BMAT, and more). My work with audio preprocessing demonstrated a relationship between frequency threshold and channel depth for optimal acoustic scene classification using convolutional neural networks (see: <a href="https://doi.org/10.5281/zenodo.3445618" target="_blank" rel="noreferrer">graduate thesis</a>).</p>
        </div>
    );
};

const nprExperience = {
    employer: 'National Public Radio',
    logo: '',
    title: 'Software Engineer',
    location: 'Washington D.C.',
    dates: '01/2019 - 07-2023',
    summary: 'Modernized radio news consumption by developing the NPR Google Home experience, bringing NPR’s visual newscast along with NPR Routines to Amazon Alexa, and maintaining NPR One on all emergent platforms, improving sponsorship revenue by the order of millions. These projects were written in TypeScript with Node.js while utilizing popular front-end frameworks such as React, Angular, or RxJS, along with XState where appropriate. Additional accomplishments:',
    bullets: ['Grew voice and emerging platforms to be NPR’s most dominant digital listening experience, increasing weekly listener hours by 216% and the usage by 140% to eclipse 80,000 daily users.',
    'Built microservices (TypeScript, Node.js, AWS Lambda) and editorial tools (React, XState, Algolia) to sunset a Java-based monolith, improving staff capacity to manage, curate, and distribute media efficiently.',
    'Supported legacy infrastructure (PHP, AWS EC2) to power internal and external NPR digital listening APIs.',
    'Wrote comprehensive tests with Jest and PHPUnit alongside thorough documentation accessible to all audiences.',
    'Created and maintained cloud-based deployment pipelines using GitHub Actions, Codefresh, and Serverless.',
    'Monitored product portfolio with Google Analytics, DataDog, and AWS CloudWatch.',
    'Presented work to stakeholders through tech designs, sprint reviews, thoughtful code reviews, and blog posts.',
    'Maintained business platform partnerships to promote NPR brand visibility and leverage pre-released technology.',
    'Changed the cultural landscape at NPR by spearheading initiatives, including the development of the Employee Resource Group (ERG) program and leadership of QTPOC, an ERG in support of queer and trans people of color at NPR.',
    'Nominated each year for company awards, winning twice for DEI and innovative work projects.'],
} 

const upfExperience = {
    employer: 'Universitat Pompeu Fabra',
    logo: '',
    title: 'Graduate Researcher',
    location: 'Barcelona, Spain',
    dates: '09/2017 - 09-2019',
    summary: 'Studied under the direction of UPF’s cross-disciplinary Music Technology Group (Essentia, Vocaloid, Freesound, Reactable, BMAT, and more). The research with audio preprocessing demonstrated a relationship between frequency threshold and channel depth for optimal acoustic scene classification using convolutional neural networks (https://doi.org/10.5281/zenodo.3445618 for full experimental details).',
    bullets: ['Challenged practice of filtering and channel reduction to show value in dynamic contexts for machine perception.',
    'Sourced data from IEEE’s AASP DCASE 2019 task set.',
    'Audio spectrograms generated using short time Fourier transforms in Essentia (C++), resulting vectors shaped with NumPy & Pandas (Python).',
    'Engineered neural networks with Keras (Python), then optimized for and trained on NVidia GPUs.'],
}

const ExperienceSummary = (props) => {
    return (
        <div>
            <p><b>{props.experienceObj.title}</b> - {props.experienceObj.employer} - {props.experienceObj.location} <br /> {props.experienceObj.dates}</p>
            <p>{props.experienceObj.summary}</p>
            {<ul>{props.experienceObj.bullets.map((item, index) => (<li key={index}>{item}</li>))}</ul>}
            <br />
        </div>
    );
}
const Resume = () => {
    return (
        <div className="resume-section">
            <h2>Education</h2>
            <p><b>Master of Science (MS) in Sound & Music Computing, 2019</b> - Universitat Pompeu Fabra, Barcelona, Spain</p>
            <p><b>Bachelor of Arts (BA) in Computer Science, 2017</b> - University of Virginia, Charlottesville, Virginia</p>
            
            <h2>Technical Skills</h2>
            <p><b>Language</b> – TypeScript | JavaScript | Python | PHP | Java | C++ | MySQL</p>
            <p><b>Toolkit</b> – Node.js | React | XState | Jest | Amazon Web Services (AWS) | Serverless | GitHub Actions | Codefresh | Jenkins | Google Analytics | DataDog | Alexa Skills Kit | Cast Application Framework | Algolia | Electron | Jira</p>
            <p><b>Research</b> – Keras | SciKit | Pandas | NumPy | Jupyter | Essentia | Google Colab</p>

            <h2>Experience</h2>
            <ExperienceSummary experienceObj={nprExperience} />
            <ExperienceSummary experienceObj={upfExperience} />
        </div>
    );
};

const Research = () => {
    return (
        <div className="research-section">
            <h2><a href="https://zenodo.org/record/3445618" target="blank" rel="noreferrer">Acoustic Scene Classification using Convolutional Neural Networks</a><br />(a summary)</h2>
            
            <h3>why...</h3>
            <p>My focus while studying with the <a href="https://www.upf.edu/web/mtg" target="_blank" rel="noreferrer">Music Technology Group</a>  at Universitat Pompeu Fabra centered around the field of <a href="https://www.semanticscholar.org/paper/Computational-Auditory-Scene-Analysis%3A-Principles%2C-Wang-Brown/88721a0f4280d7f94ba151f9d4fbe71dd9d809b3" target="_blank" rel="noreferrer">Computational Auditory Scene Analysis (CASA)</a>. CASA is best understood as “the study of auditory scenes by acoustic means,” where auditory (or “acoustic”) scenes refer to taxonomized contexts understood through psychophysical descriptors, e.g., in nature, at a sports arena, in the kitchen, on a bus, etc. The advancement of acoustic event and scene analysis has been propelled by its deployable industry value be it in surveillance technologies, Internet of Things (IoT), context-aware applications, along with a host of other sectors that require advanced media retrieval. This line of inquisition felt like a natural pairing to the consumer-facing IoT devices I was set to develop for during my start at NPR. Understanding the capabilities and limitations of commodity IoT hardware (such as a smart speaker) from a platform standpoint allowed for a better development - and ultimately user - experience on the client side. </p>
            
            <h3>what...</h3>
            <p>The <a href="https://dcase.community/" target="_blank" rel="noreferrer">Detection and Classification of Acoustic Scenes and Events (DCASE)</a> research community publishes an annual challenge for the advancement of CASA. I used the DCASE Acoustic Scene Analysis challenge dataset for my experiments to investigate different audio preprocessing techniques and a variety of neural networks in search of practical optimizations to facilitate growth of the field. To prepare the dataset for consumption, I generated audio spectrograms using short time Fourier transforms, frequency was then reduced logarithmically to the Mel scale, then the resulting vectors were shaped into slices of the input.</p>
            <figure>
                <img className="spectrogram" src={spectrograms} alt="graphic representation of spectrograms"></img>
                <figcaption>examples of an audio spectrogram - whole on the left, cut into slices on the right.</figcaption>
            </figure>
            
            <h3>how...</h3>
            <p>Many variations of the dataset were generated: two-channel audio and single channel representation, with low-pass filtered versions of each at 1KHz, 10KHz, and full audible range (22KHz). Additional experimentation came in the form of three styles of convolutional neural (CNN) networks to test with: one wide, one deep, and one generic. All three networks leveraged batch normalization with dropout, max pooling, zero padding, and rectified linear unit (ReLU) activation functions across each layer save for the dense fully connected output layer which used softmax activation. The deep network was modeled after <a href="https://arxiv.org/abs/1704.04861" target="blank" rel="noreferrer">MobileNet</a> (built to run with the computational limitations of commodity hardware in mind), the generic network incorporated three convolutional layers with identical receptive fields across each layer, while the wide network utilized one less convolutional layer in exchange for four increasingly taller receptive fields in its first layer. The neural networks were engineered with Keras then optimized for and trained on NVidia GPUs.</p>
            
            <ResearchSlides></ResearchSlides>

            <h3>and...</h3>
            <p>Ultimately my work demonstrated a relationship between frequency threshold and channel depth for optimal acoustic scene classification. While the wide CNN's increased width helped compensate for substandard audio, the deeper CNN outperformed the rest irrespective of modifications made to the dataset. Further the deep network performed best when presented with two channel audio over single channel inputs, and the dropoff in classification accuracy when presented with filtered audio was not significant enough to warrant foregoing the savings cost brought by reduced frequency range. The implications of this can easily be applied to classifiers embedded on commodity hardware where access to robust computational power and hi fidelity microphones are often limited.</p>
            
            {/* <DeltaSlides></DeltaSlides> */}

            <p>For a full picture of the experiments conducted, the stack, and a broader analysis see:<br/ ><li><a href="https://zenodo.org/records/3445618" target="_blank" rel="noreferrer">Acoustic Scene Classification using Convolutional Neural Networks on Multivariate Audio</a></li></p>
        </div>
    );
};


export {
    About,
    Research,
    Resume,
};    