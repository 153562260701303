/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {ImageCarousel, Placeholder} from "./ImageCarousel";

// TODO
// change <a> tags to styled buttons?
// adjust css of menubar to be mobile friendly
// have the sidebar color change with each site visit


const MenuBarImageView = () => {
    const [hasElapsed, setHasElapsed] = useState(false);

    let duration = hasElapsed ? 20750 : 39250;  
  
    // every {duration} the image view switches from placeholder to carousel 
    useEffect(() => {
      const timer = setTimeout(() => {
        setHasElapsed(!hasElapsed)
      }, duration); 
  
      return () => clearTimeout(timer);
    });// no dependency array keeps it going back and forth

    return (
      <div>
        {hasElapsed ? <ImageCarousel/> : <Placeholder/>}
      </div>
    );
  };

const MenuBar = ({ onComponentChange, activeComponent }) => {
    return (
      <div className='menu-bar-container'>
      <div className="menu-bar">
            <MenuBarImageView/>
            <div className="menu-items">
              <nav>
                <ul><a href='#' onClick={() => onComponentChange('About')}>{activeComponent === 'About' ? '• About •' : 'About'}</a></ul>
                <ul><a href='#' onClick={() => onComponentChange('Resume')}>{activeComponent === 'Resume' ?'• Resume •' : 'Resume'}</a></ul>
                <ul><a href='#' onClick={() => onComponentChange('Research')}>{activeComponent === 'Research' ? '• Thesis •' : 'Thesis'}</a></ul>
                <ul>• • •</ul>
                <ul><a href='#' onClick={() => onComponentChange('Contact')}>{activeComponent === 'Contact' ? '• Contact •' : 'Contact'}</a></ul>
                <ul><a href="https://www.linkedin.com/in/marc-k-jones/" target="_blank" rel="noopener noreferrer">LinkedIn</a></ul>
              </nav>

            </div>
        </div>
      </div>
    );
  }

export default MenuBar;

