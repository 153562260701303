import React, { useState } from 'react';
import loadingPic from '../components/assets/loading.gif'

const GoogleForm = () => {
  const [isLoading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div className='iframe-container'>
      
      {isLoading && <img className="loading-image" src={loadingPic} alt='loading'/>}
      
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLSfLm7iE67yInYCEhKSCmygkX3og_AcxJJYqlzorD5wnlHLUFw/viewform?embedded=true" 
        // frameborder="0" 
        // marginheight="0" 
        // marginwidth="0" 
        onLoad={handleIframeLoad}
        title='Contact Form'>
      </iframe>

    </div>
  );
}

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic for handling form submission, e.g., sending data to a server
    // console.log('Form data submitted:', formData);
    // Reset the form after submission
    // setFormData({
    //   name: '',
    //   email: '',
    //   message: '',
    // });
  };

  return (
    <div>
      <form action="https://formspree.io/f/mknlllqy" method="POST">
        <label>
          Your email:
          <input type="email" name="email" />
        </label>
        <label>
          Your message:
          <textarea name="message"></textarea>
        </label>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export {
  ContactForm,
  GoogleForm,
};