/* eslint-disable no-useless-escape */

const welcomeMessage =
`__   __   __   ______  $$ |  _______   ______   _____  ____    ______  
/  | /  | /  | /      \ $$ | /       | /      \ /     \/    \  /      \ 
$$ | $$ | $$ |/$$$$$$  |$$ |/$$$$$$$/ /$$$$$$  |$$$$$$ $$$$  |/$$$$$$  |
$$ | $$ | $$ |$$    $$ |$$ |$$ |      $$ |  $$ |$$ | $$ | $$ |$$    $$ |
$$ \_$$ \_$$ |$$$$$$$$/ $$ |$$ \_____ $$ \__$$ |$$ | $$ | $$ |$$$$$$$$/ 
$$   $$   $$/ $$       |$$ |$$       |$$    $$/ $$ | $$ | $$ |$$       |
$$$$$/$$$$/   $$$$$$$/ $$/  $$$$$$$/  $$$$$$/  $$/  $$/  $$/  $$$$$$$/                                                
`                                                

export {
    welcomeMessage
}