/* eslint-disable jsx-a11y/anchor-is-valid */

import { simplecnn, widecnn, deepcnn, deltacnn, deltachannel, deltafreq } from './Images';
import React, { useState, useEffect } from 'react';

const neuralNetSlides = {
    simple: { title: 'Simple CNN', img: simplecnn },
    wide: { title: 'Wide CNN', img: widecnn },
    deep: { title: 'Deep CNN', img: deepcnn }, 
}

const deltaSlides = {
    cnn: { title: 'Delta by CNN', img: deltacnn },
    channel: { title: 'Delta by Channel', img: deltachannel },
    freq: { title: 'Delta by Freq', img: deltafreq }, 
}

const ResearchSlides = () => {
    const [slideIndex, setSlideIndex] = useState(1);
  
    const plusSlides = (n) => { showSlides(slideIndex + n) };
  
    const currentSlide = (n) => { showSlides(n) };
  
    const showSlides = (n) => {
      const slides = document.getElementsByClassName("mySlides");
      const dots = document.getElementsByClassName("dot");
  
      if (n > slides.length) {
        setSlideIndex(1);
      } else if (n < 1) {
        setSlideIndex(slides.length);
      } else {
        setSlideIndex(n);
      }
  
      // Hide all slides // candidate for deletion
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
  
      // Remove "active" class from all dots
      for (let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
  
      // Display the current slide and add "active" class to the corresponding dot
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
    };
  
    // useEffect to run the initial showSlides
    useEffect(() => {
      showSlides(slideIndex);
    }, []);
  
    return (
      <div className="slideshow-container">
          <br />
          <div className="mySlides fade">
              <div className="text">Simple CNN (1/3)</div>
              <img src={simplecnn} style={{ width: '100%' }} alt="Simple CNN" />
          </div>
  
          <div className="mySlides fade">
              <div className="text">Wide CNN (2/3)</div>
              <img src={widecnn} style={{ width: '100%' }} alt="Wide CNN" />
          </div>
  
          <div className="mySlides fade">
              <div className="text">Deep CNN (3/3)</div>
              <img src={deepcnn} style={{ width: '100%' }} alt="Deep CNN" />
          </div>
  
        {/* Next and previous buttons */}
        <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
        <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
  
        <br />
  
        {/* The dots/circles */}
        <div style={{ textAlign: 'center' }}>
          <span className="dot" onClick={() => currentSlide(1)}></span>
          <span className="dot" onClick={() => currentSlide(2)}></span>
          <span className="dot" onClick={() => currentSlide(3)}></span>
        </div>
  
        <br />
      </div>
    );
  };
  
const DeltaSlides = () => {
    const [slideIndex, setSlideIndex] = useState(1);

    const plusSlides = (n) => { showSlides(slideIndex + n) };
  
    const currentSlide = (n) => { showSlides(n) };
  
    const showSlides = (n) => {
      const slides = document.getElementsByClassName("mySlides");
      const dots = document.getElementsByClassName("dot");
  
      if (n > slides.length) {
        setSlideIndex(1);
      } else if (n < 1) {
        setSlideIndex(slides.length);
      } else {
        setSlideIndex(n);
      }
  
      // Hide all slides // candidate for deletion
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
  
      // Remove "active" class from all dots
      for (let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
  
      // Display the current slide and add "active" class to the corresponding dot
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
    };
  
    // useEffect to run the initial showSlides
    useEffect(() => {
      showSlides(slideIndex);
    }, []);
  
    return (
      <div className="slideshow-container">
          <br />
          <div className="mySlides fade">
              <div className="text">Delta in Classification Accuracy by Neural Net (1/3)</div>
              <img src={deltacnn} style={{ width: '100%' }} alt="Delta in Classification Accuracy by Neural Net" />
          </div>
  
          <div className="mySlides fade">
              <div className="text">Delta in Classification Accuracy by Channel Size (2/3)</div>
              <img src={deltachannel} style={{ width: '100%' }} alt="Delta in Classification Accuracy by Channel Size" />
          </div>
  
          <div className="mySlides fade">
              <div className="text">Delta in Classification Accuracy by Frequency Bands (3/3)</div>
              <img src={deltafreq} style={{ width: '100%' }} alt="Delta in Classification Accuracy by Frequency Bands" />
          </div>
  
        {/* Next and previous buttons */}
        {/* <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
        <a className="next" onClick={() => plusSlides(1)}>&#10095;</a> */}
        <button className="prev" onClick={() => plusSlides(-1)}>&#10094;</button>
        <button className="next" onClick={() => plusSlides(1)}>&#10095;</button>
  
        <br />
  
        {/* The dots/circles */}
        <div style={{ textAlign: 'center' }}>
          <span className="dot" onClick={() => currentSlide(1)}></span>
          <span className="dot" onClick={() => currentSlide(2)}></span>
          <span className="dot" onClick={() => currentSlide(3)}></span>
        </div>
  
        <br />
      </div>
    );
  };
  
export {
    ResearchSlides,
    DeltaSlides
}
